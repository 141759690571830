import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrandCampaignService } from "../brand/brand-campaign/shared/services/brand-campaign.service";
import { SharedProviderModule } from "../features/shared/shared-providers.module";
import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { LangSelectorComponent } from "./lang-selector/lang-selector.component";
import { LayoutPublicComponent } from "./layout-public/layout-public.component";

@NgModule({
  declarations: [LayoutPublicComponent, LangSelectorComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    RouterModule,
    SharedProviderModule,
  ],
  exports: [LayoutPublicComponent, LangSelectorComponent],
  providers: [BrandCampaignService],
})
export class LayoutModule {}
