import { Component } from "@angular/core";
import { isWhiteLabel } from "../../shared/services/api/authentication.service";

@Component({
  selector: "app-layout-public",
  templateUrl: "./layout-public.component.html",
  styleUrl: "./layout-public.component.scss",
})
export class LayoutPublicComponent {
  protected readonly isWhiteLabel = isWhiteLabel();
}
