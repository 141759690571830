import { Component, OnInit, computed, signal } from "@angular/core";

import { AppDataService } from "../../shared/services/api/app-data.service";
import { LanguageService } from "../../shared/services/language.service";
import { AppDataLanguage, AppLocale } from "../../shared/models/appData";

@Component({
  selector: "app-lang-selector",
  templateUrl: "./lang-selector.component.html",
  styleUrls: ["./lang-selector.component.scss"],
})
export class LangSelectorComponent implements OnInit {
  public languages = signal<AppDataLanguage[]>([]);
  public language = computed(() =>
    this.languages().find(({ code }) => code === this.languageService.locale),
  );

  constructor(
    private readonly appDataService: AppDataService,
    private readonly languageService: LanguageService,
  ) {}

  public ngOnInit(): void {
    this.appDataService.get().then((appData) => {
      this.languages.set(
        appData.languages.filter((lang) => !(lang.notForUI ?? false)),
      );
    });
  }

  public setLocale(locale: AppLocale): void {
    this.languageService.setLocale(locale);
  }
}
