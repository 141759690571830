<ng-container *ngIf="language()">
  <button class="button">
    <img src="/assets/images/icons/flags/ic_flag_{{ language().code }}.png" />
    <div class="arrow-down"></div>
  </button>
  <ul class="lang-menu-list">
    <li class="lang-menu-item" *ngFor="let language of languages()">
      <a
        class="lang-menu-item-anchor"
        (click)="setLocale(language.code)"
        id="edit-profile"
      >
        {{ language.name }}
        <img
          src="/assets/images/icons/flags/ic_flag_{{ language.code }}.png"
          alt="{{ language.code }}"
        />
      </a>
    </li>
  </ul>
</ng-container>
